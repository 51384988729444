import Image, { ImageProps } from "next/image";
import { FC } from "react";
import { useMedia } from "react-use";


const ImageCustom: FC<ImageProps> = (props) => {
  // For the .pdf export, avoid lazy loading images
  const isPrint = useMedia("print", false);
  const loading = isPrint ? "eager" : props.loading;

  return (
    <Image
      {...props}
      alt={props.alt}
      loading={loading}
      // @ts-expect-error
      style={{ objectFit: props.objectFit, ...props.style }}
    />
  );
};

ImageCustom.defaultProps = { loading: "lazy" };

export default ImageCustom;
