import classNames from "classnames";
import Link from "next/link";
import { FC } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useNavigationContext } from "../context/NavigationContext";
import { getPdfUrlFromSlug } from "../utils/pdf.utils";
import Button from "./Button";
import styles from "./NavigationPanel.module.scss";
import Socials from "./Socials";
import Typo from "./Typo";

export interface NavigationPanelProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
}

const NavigationPanel: FC<NavigationPanelProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const { chapters, chaptersReached, menu, slug } = useNavigationContext();
  const currentMenuIndex = menu.findIndex((menuItem) => menuItem.slug === slug);

  // TODO enable if we find a solution regarding re-redering and perfs
  // const handleClickChapter =
  //   (chapterIndex: number) => (e: React.MouseEvent) => {
  //     e.preventDefault();
  //     refsChapters[chapterIndex]?.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   };

  return (
    <div
      className={classNames(
        styles.container,
        isMenuOpen && styles.container_displayMobile
      )}
    >
      <div className={styles.menuWrap}>
        <div className={styles.menuContainer}>
          {menu.map((menuItem, index) => (
            <div key={menuItem.title} className={styles.menuSegment}>
              <Link
                href={`/${menuItem.slug}`}
                scroll={false}
                className={styles.navigationLink}
                onClick={() => setIsMenuOpen(false)}
              >
                <Typo
                  variant="h5"
                  color="light"
                  className={classNames(
                    styles.page,
                    currentMenuIndex === index && styles.page_current
                  )}
                >
                  {menuItem.title}
                </Typo>
              </Link>
              {currentMenuIndex === index && (
                <div
                  className={classNames(
                    styles.subMenu,
                    currentMenuIndex === index && styles.subMenuActive
                  )}
                >
                  {chapters.map(
                    (chapter, chapterIndex) =>
                      chapter.title && (
                        <a
                          key={chapterIndex}
                          href={`#${chapter.id}`}
                          onClick={() => setIsMenuOpen(false)}
                          // onClick={handleClickChapter(chapterIndex)}
                        >
                          <Typo
                            key={chapter.title}
                            variant="chapter"
                            color="light"
                            className={classNames(
                              styles.chapter,
                              chaptersReached.includes(chapterIndex) &&
                                styles.chapter_reached
                            )}
                          >
                            {chapter.title}
                          </Typo>
                        </a>
                      )
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.downloadContainer}>
        <Typo variant="download" color="secondary">
          Télécharger le rapport d&apos;activité <br />
          complet au format PDF:
        </Typo>
        <div className={styles.buttonContainer}>
          <Button
            className={classNames(styles.button)}
            color="secondary"
            icon={AiOutlineFilePdf}
            text="Télécharger"
            href={getPdfUrlFromSlug("full")}
            isExternal={true}
          />
        </div>
      </div>
      <div className={styles.socials}>
        <Socials />
      </div>
    </div>
  );
};

NavigationPanel.defaultProps = {};

export default NavigationPanel;
