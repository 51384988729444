import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";
import Container from "./Container";
import styles from "./Footer.module.scss";
import ImageCustom from "./ImageCustom";
import Typo from "./Typo";

const Footer: FC = () => {
  const openCookiesModal = (e: React.MouseEvent) => {
    e.preventDefault();
    // tarteaucitron is declare as a global variable in global.d.ts
    tarteaucitron?.userInterface?.openPanel();
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Container className={styles.subContainer}>
          <Link href="/" scroll={false}>
            <ImageCustom
              src={"/vector/logo-white.svg"}
              alt="Logo e-santé occitanie"
              width={156}
              height={50}
              layout="fixed"
              className={styles.logo}
            />
          </Link>
          <Typo variant="h6">
            Consultez le site internet pour plus d&apos;informations :<br />
            <a className={styles.link} href="https://esante-occitanie.fr">
              esante-occitanie.fr
            </a>
          </Typo>
          <Link href="/mentions-legales" scroll={false} className={styles.link}>
            <Typo variant="h6">Mentions légales</Typo>
          </Link>
          <Link
            href="/politique-de-confidentialite"
            scroll={false}
            className={styles.link}
          >
            <Typo variant="h6">Politique de confidentialité</Typo>
          </Link>
          <button
            className={classNames(styles.link, styles.button)}
            onClick={openCookiesModal}
          >
            <Typo variant="h6">Gestion des cookies</Typo>
          </button>
        </Container>
      </div>
    </div>
  );
};

Footer.defaultProps = {};

export default Footer;
