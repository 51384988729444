import Image from "next/image";
import { FC } from "react";
import { useNavigationContext } from "../context/NavigationContext";
import CTA from "./CTA";
import Container from "./Container";
import styles from "./NavigationBottom.module.scss";

const NavigationBottom: FC = () => {
  const { menu, slug } = useNavigationContext();
  const currentMenuIndex = menu.findIndex((menuItem) => menuItem.slug === slug);

  const previousMenuItem = menu[currentMenuIndex - 1];
  const nextMenuItem = menu[currentMenuIndex + 1];

  return (
    <div className={styles.container}>
      <Container className={styles.subContainer}>
        {previousMenuItem && (
          <div className={styles.nav_left}>
            <CTA
              text={`Revenir vers le chapitre précédent : ${previousMenuItem.title}`}
              color="secondary"
              href={`/${previousMenuItem.slug}`}
              reverse={true}
              type="navigation"
            />
          </div>
        )}
        {nextMenuItem && (
          <div className={styles.nav_right}>
            <CTA
              text={`Poursuivre la lecture vers le prochain chapitre : ${nextMenuItem.title}`}
              color="primary"
              href={`/${nextMenuItem.slug}`}
              type="navigation"
            />
          </div>
        )}
      </Container>
      <div className={styles.picto}>
        <Image
          src={"/vector/footer-picto.svg"}
          alt=""
          width={350}
          height={200}
          layout="fixed"
        />
      </div>
    </div>
  );
};

NavigationBottom.defaultProps = {};

export default NavigationBottom;
