import classNames from "classnames";
import { FC } from "react";
import socialNetworks from "../config/socialNetworks";
import Button from "./Button";
import styles from "./Socials.module.scss";
import Typo from "./Typo";

interface SocialsProps {
  className?: string;
}

const Socials: FC<SocialsProps> = ({ className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Typo variant="socials" color="light">
        Suivez nous :
      </Typo>
      <div className={styles.wrapper}>
        {Object.keys(socialNetworks).map((item) => (
          <Button
            key={socialNetworks[item].name}
            className={classNames(styles.button)}
            icon={socialNetworks[item].picto}
            color="light"
            href={socialNetworks[item].link}
            isExternal
            ariaLabel={socialNetworks[item].name}
          />
        ))}
      </div>
    </div>
  );
};

Socials.defaultProps = {};

export default Socials;
