import classNames from "classnames";
import { FC, ReactNode } from "react";
import styles from "./Container.module.scss";

export interface ContainerProps {
  children: ReactNode;
  className?: string;
}

const Container: FC<ContainerProps> = ({ className, children }) => {
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
};

Container.defaultProps = {};

export default Container;
