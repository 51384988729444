import classNames from "classnames";
import Link from "next/link";
import { FC } from "react";
import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { getPdfUrlFromSlug } from "../utils/pdf.utils";
import styles from "./CTA.module.scss";
import Typo from "./Typo";

export interface ICTAProps {
  type?: "download-report" | "navigation";
  color?: "primary" | "secondary";
  href?: string;
  text?: string;
  reverse?: boolean;
  className?: string;
}

const CTA: FC<ICTAProps> = ({
  type,
  color,
  href: hrefProps,
  text,
  reverse,
  className,
}) => {
  let Icon = type === "download-report" ? BsFileEarmarkArrowDown : FaArrowRight;
  if (reverse && type !== "download-report") {
    Icon = FaArrowLeft;
  }

  const href =
    type === "download-report" ? getPdfUrlFromSlug("full") : hrefProps ?? "";

  const renderCTA = () => (
    <div
      className={classNames(
        styles.container,
        styles[`container_${color}`],
        reverse && styles.container_reverse,
        className
      )}
    >
      <div
        className={classNames(
          styles.contentWrapper,
          reverse && styles.contentWrapper_reverse,
          styles.text
        )}
      >
        <Typo variant="cta" color="light">
          {text}
        </Typo>
      </div>
      <div
        className={classNames(
          styles.iconWrapper,
          styles[`iconWrapper_${color}`]
        )}
      >
        <Icon
          className={classNames(
            styles.icon,
            styles[`icon_${color}`],
            styles.icon_only
          )}
        />
      </div>
    </div>
  );
  return type === "download-report" ? (
    <a
      href={href}
      className={classNames(styles.link, styles[`link_${color}`])}
      rel="noopener noreferrer"
      target="_blank"
    >
      {renderCTA()}
    </a>
  ) : (
    <Link
      href={href}
      scroll={false}
      className={classNames(styles.link, styles[`link_${color}`])}
    >
      {renderCTA()}
    </Link>
  );
};

CTA.defaultProps = {
  type: "download-report",
  reverse: false,
};

export default CTA;
