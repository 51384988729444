import classNames from "classnames";
import fileSize from "filesize";
import { FC } from "react";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import ApiDownload from "../models/api/utils/dowload.model";
import Button from "./Button";
import styles from "./Download.module.scss";
import Typo from "./Typo";

export interface DownloadProps extends ApiDownload {
  className: string;
}

const Download: FC<DownloadProps> = ({
  className,
  buttonLabel,
  description,
  file,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Typo variant="h4" className={styles.title}>
        {description}
      </Typo>
      {file.data?.attributes?.size ? (
        <Typo className={styles.size}>
          Poids du fichier : {fileSize(file.data?.attributes?.size * 1000)}
        </Typo>
      ) : (
        ""
      )}
      <Button
        href={file.data?.attributes?.url}
        text={buttonLabel}
        icon={HiOutlineDocumentDownload}
        className={styles.button}
        isDownload
      />
    </div>
  );
};

Download.defaultProps = {};

export default Download;
